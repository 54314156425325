import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import AnaSize from "./pages/AnaSize";
import MobilSize from "./pages/MobilSize";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  // Update the `isMobile` state when window size changes
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Redirect based on device type
  useEffect(() => {
    if (isMobile && pathname === "/") {
      navigate("/mobil");
    }
  }, [isMobile, pathname, navigate]);

  useEffect(() => {
    if (pathname !== "/") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Ana Sayfa";
        metaDescription = "Ana sayfanın açıklaması.";
        break;
      case "/mobil":
        title = "Mobil Sayfa";
        metaDescription = "Mobil sayfanın açıklaması.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<AnaSize />} />
      <Route path="/mobil" element={<MobilSize />} />
    </Routes>
  );
}

export default App;

import Field from "./Field";
import Button from "./Button";
import PropTypes from "prop-types";
import "./ContactForm.css";

const ContactForm = ({ className = "" }) => {
  return (
    <div className={`contact-form ${className}`}>
      <div className="block-with-illustration">
        <div className="contact-type">
          <div className="radiobutton-parent">
            <input className="radiobutton" type="radio" />
            <div className="ikayet-wrapper">
              <div className="ikayet">Şikayet</div>
            </div>
          </div>
          <div className="radiobutton-group">
            <input className="radiobutton1" type="radio" />
            <div className="ikayet-wrapper">
              <div className="destek1">Destek</div>
            </div>
          </div>
          <div className="radiobutton-group">
            <input className="radiobutton1" type="radio" />
            <div className="ikayet-wrapper">
              <div className="zellik-talebi">Özellik Talebi</div>
            </div>
          </div>
        </div>
        <div className="fields">
          <Field sim="İsim" simPlaceholder="İsim" />
          <Field
            sim="Email*"
            propMinWidth="49px"
            simPlaceholder="Email"
            propWidth="45px"
          />
          <div className="field1">
            <div className="mesaj">Mesaj*</div>
            <textarea
              className="input1"
              placeholder="Mesaj"
              rows={10}
              cols={28}
            />
          </div>
          <img
            className="illustration-icon8"
            alt=""
            src="/illustration-8@2x.png"
          />
        </div>
        <Button
          propBorder="none"
          propBackgroundColor="#191a23"
          propWidth="556px"
          propAlignSelf="unset"
          label="Gönder"
          propColor="#fff"
          propTextDecoration="unset"
          propDisplay="inline-block"
          propMinWidth="71px"
        />
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  className: PropTypes.string,
};

export default ContactForm;

import { useMemo } from "react";
import PropTypes from "prop-types";
import "./CardsSection1.css";

const CardsSection1 = ({
  className = "",
  propHeight,
  propBackgroundColor,
  propPadding,
  propGap,
  propPadding1,
  propAlignSelf,
  yapayZekal,
  propAlignSelf1,
  kapAnalizi,
  propMargin,
  illustration,
  propAlignSelf2,
  propOverflow,
  propWidth,
  icon,
  label,
  propColor,
  propFlex,
  propBackgroundColor1,
  propHeight1,
  propPadding2,
  propGap1,
  finansalVeriler,
  veBilanolar,
  propDisplay,
  propMinWidth,
  illustration1,
  icon1,
  label1,
}) => {
  const cardsSectionStyle = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  const card2Style = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const frameDivStyle = useMemo(() => {
    return {
      padding: propPadding,
      gap: propGap,
    };
  }, [propPadding, propGap]);

  const heading2Style = useMemo(() => {
    return {
      padding: propPadding1,
    };
  }, [propPadding1]);

  const label4Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const yapayZekalStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
    };
  }, [propAlignSelf1]);

  const kapAnaliziStyle = useMemo(() => {
    return {
      margin: propMargin,
    };
  }, [propMargin]);

  const illustrationIcon1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf2,
      overflow: propOverflow,
      width: propWidth,
    };
  }, [propAlignSelf2, propOverflow, propWidth]);

  const label5Style = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  const card3Style = useMemo(() => {
    return {
      flex: propFlex,
      backgroundColor: propBackgroundColor1,
      height: propHeight1,
    };
  }, [propFlex, propBackgroundColor1, propHeight1]);

  const frameDiv1Style = useMemo(() => {
    return {
      padding: propPadding2,
      gap: propGap1,
    };
  }, [propPadding2, propGap1]);

  const veBilanolarStyle = useMemo(() => {
    return {
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propDisplay, propMinWidth]);

  return (
    <section
      className={`cards-section4 ${className}`}
      style={cardsSectionStyle}
    >
      <div className="card8" style={card2Style}>
        <div className="heading-and-link8">
          <div className="heading-parent1" style={frameDivStyle}>
            <div className="heading12" style={heading2Style}>
              <div className="label49" style={label4Style}>
                <div className="yapay-zekal" style={yapayZekalStyle}>
                  {yapayZekal}
                </div>
              </div>
              <div className="label49">
                <div className="yapay-zekal" style={kapAnaliziStyle}>
                  {kapAnalizi}
                </div>
              </div>
            </div>
            <img
              className="illustration-icon9"
              loading="lazy"
              alt=""
              src={illustration}
              style={illustrationIcon1Style}
            />
          </div>
          <div className="link8">
            <img className="icon8" loading="lazy" alt="" src={icon} />
            <div className="label51" style={label5Style}>
              {label}
            </div>
          </div>
        </div>
      </div>
      <div className="card9" style={card3Style}>
        <div className="heading-and-link9">
          <div className="heading-parent2" style={frameDiv1Style}>
            <div className="heading13">
              <div className="label52">
                <div className="yapay-zekal">{finansalVeriler}</div>
              </div>
              <div className="label53">
                <div className="yapay-zekal" style={veBilanolarStyle}>
                  {veBilanolar}
                </div>
              </div>
              <div className="label54">
                <div className="label55">Label</div>
              </div>
            </div>
            <img
              className="illustration-icon10"
              loading="lazy"
              alt=""
              src={illustration1}
            />
          </div>
          <div className="link9">
            <img className="icon8" loading="lazy" alt="" src={icon1} />
            <div className="label51">{label1}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

CardsSection1.propTypes = {
  className: PropTypes.string,
  yapayZekal: PropTypes.string,
  kapAnalizi: PropTypes.string,
  illustration: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  finansalVeriler: PropTypes.string,
  veBilanolar: PropTypes.string,
  illustration1: PropTypes.string,
  icon1: PropTypes.string,
  label1: PropTypes.string,

  /** Style props */
  propHeight: PropTypes.any,
  propBackgroundColor: PropTypes.any,
  propPadding: PropTypes.any,
  propGap: PropTypes.any,
  propPadding1: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propAlignSelf1: PropTypes.any,
  propMargin: PropTypes.any,
  propAlignSelf2: PropTypes.any,
  propOverflow: PropTypes.any,
  propWidth: PropTypes.any,
  propColor: PropTypes.any,
  propFlex: PropTypes.any,
  propBackgroundColor1: PropTypes.any,
  propHeight1: PropTypes.any,
  propPadding2: PropTypes.any,
  propGap1: PropTypes.any,
  propDisplay: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default CardsSection1;

import Heading from "./Heading";
import Button from "./Button";
import PropTypes from "prop-types";
import "./FooterBlock.css";

const FooterBlock = ({ className = "" }) => {
  return (
    <div className={`footer-block ${className}`}>
      <footer className="footer">
        <div className="navigation-and-contacts">
          <div className="elements">
            <div className="footer-brand-link">
              <img className="frame-6-11" alt="" src="/frame-6-1-1@2x.png" />
              <div className="brand-name">
                <div className="finans3601">Finans360</div>
                <div className="ile-yatrm-yapmak1">
                  ‘ile Yatırım Yapmak Ne Kolaymış
                </div>
              </div>
            </div>
            <div className="navigation">
              <div className="zelliklerimiz2">Özelliklerimiz</div>
              <div className="hakkmzda1">Hakkımızda</div>
              <div className="destek2">Destek</div>
            </div>
          </div>
          <div className="contact-info">
            <div className="contact-us">
              <Heading />
              <div className="info">
                <div className="telefon-1-650-457-0731">
                  Telefon : +1-650-457-0731 
                </div>
                <div className="adres-1101-new">
                  Adres: 1101 New York Ave NW, Washington, DC 20005, Amerika
                  Birleşik Devletleri
                </div>
              </div>
            </div>
            <div className="subscription-block">
              <div className="field2">
                <input className="email" placeholder="Email" type="text" />
              </div>
              <Button label="Yeniliklerden Haberdar Olun" />
            </div>
          </div>
        </div>
        <div className="line-and-text">
          <div className="line-and-text-child" />
          <div className="text">
            <div className="finans3602">© 2024 Finans360.</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

FooterBlock.propTypes = {
  className: PropTypes.string,
};

export default FooterBlock;

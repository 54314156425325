import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent2 from "../components/FrameComponent2";
import CardsSection1 from "../components/CardsSection1";
import FrameComponent3 from "../components/FrameComponent3";
import FooterBlock1 from "../components/FooterBlock1";
import "./MobilSize.css";

const MobilSize = () => {
  return (
    <div className="mobil-size">
      <FrameComponent1 />
      <FrameComponent2 />
      <CardsSection1
        yapayZekal="Yapay Zekalı"
        kapAnalizi="Kap Analizi"
        illustration="/illustration1@2x.png"
        icon="/icon.svg"
        label="Kap haberleri anlık olarak yapay zeka ile analizlernir ve 1-10 puan arasından finansal olarak puanlanır."
        finansalVeriler="Finansal Veriler"
        veBilanolar="ve Bilançolar"
        illustration1="/illustration-13@2x.png"
        icon1="/icon.svg"
        label1="Şirketlerin finansal verileri en doğru şekilde arşivlenir, analizlenir ve listelenip sizlere sunulur"
      />
      <CardsSection1
        propHeight="1076.9px"
        propBackgroundColor="#000"
        propPadding="unset"
        propGap="15px"
        propPadding1="unset"
        propAlignSelf="stretch"
        yapayZekal="Aracı Kurup Raporları"
        propAlignSelf1="stretch"
        kapAnalizi="Ve Önerileri"
        propMargin="0"
        illustration="/illustration-21@2x.png"
        propAlignSelf2="unset"
        propOverflow="unset"
        propWidth="210px"
        icon="/icon-2.svg"
        label="+15 den Fazla Aracı kurumun günlük ve anlık paylaştığı hisse önerileri ve raporları sizlere sunulur."
        propColor="#fff"
        propFlex="unset"
        propBackgroundColor1="#078211"
        propHeight1="494px"
        propPadding2="unset"
        propGap1="15px"
        finansalVeriler="Aracı Kurumların"
        veBilanolar="İşlemleri"
        propDisplay="inline-block"
        propMinWidth="119px"
        illustration1="/illustration-3@2x.png"
        icon1="/icon-3.svg"
        label1="Aracı kurumların hangi hissede ne akdar işlem yaptığı veya elinde ne kadar tuttuğunu anlık olarak görün."
      />
      <section className="cards-section">
        <div className="card">
          <div className="heading-and-link">
            <div className="heading-parent">
              <div className="heading1">
                <div className="label6">
                  <h2 className="sanal-portfym">Sanal Portföyüm</h2>
                </div>
              </div>
              <img
                className="illustration-icon"
                loading="lazy"
                alt=""
                src="/illustration-41@2x.png"
              />
            </div>
            <div className="link">
              <img className="icon" loading="lazy" alt="" src="/icon.svg" />
              <div className="label7">
                Sizlere sunduğumuz 100 Bin TL lik deneme bakiyesi ile gerçek
                alım-satım yapıyormuş gibi işlemler yapın ve tecrübe edinin.
              </div>
            </div>
          </div>
        </div>
        <div className="card1">
          <div className="heading-and-link1">
            <div className="heading-group">
              <div className="heading2">
                <div className="label8">
                  <div className="likileri-ve">İş İlişkileri ve</div>
                </div>
                <div className="label9">
                  <div className="temettler">Temettüler</div>
                </div>
                <div className="label10">
                  <div className="label11">Label</div>
                </div>
              </div>
              <img
                className="illustration-icon1"
                loading="lazy"
                alt=""
                src="/illustration-51@2x.png"
              />
            </div>
            <div className="link1">
              <img className="icon" loading="lazy" alt="" src="/icon.svg" />
              <div className="label7">
                Şirketlerin yaptığı iş anlaşmalarını anlık olarak görün ,
                verdiği temettüleri yıllık olarak analizleyin.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cards-section1">
        <div className="card2">
          <div className="heading-and-link2">
            <div className="heading-container">
              <div className="heading3">
                <div className="label13">
                  <h2 className="tedbirli-hisseler-ve">Tedbirli Hisseler ve</h2>
                </div>
                <div className="label14">
                  <h2 className="sanal-portfym">Spk Cezaları</h2>
                </div>
              </div>
              <img
                className="illustration-icon"
                loading="lazy"
                alt=""
                src="/illustration-61@2x.png"
              />
            </div>
            <div className="link2">
              <img className="icon" loading="lazy" alt="" src="/icon-2.svg" />
              <div className="label7">
                Hisselerin üzerinde bulunan tedbirleri ve spk nın kişilere ve
                kurumlara verdiği cezaları görün.
              </div>
            </div>
          </div>
        </div>
        <div className="card3">
          <div className="heading-and-link1">
            <div className="frame-div">
              <div className="heading4">
                <div className="label8">
                  <div className="temettler">Anlık Alım-Satım</div>
                </div>
                <div className="label9">
                  <div className="analizi">Analizi</div>
                </div>
                <div className="label10">
                  <div className="label11">Label</div>
                </div>
              </div>
              <img
                className="illustration-icon"
                loading="lazy"
                alt=""
                src="/illustration-71@2x.png"
              />
            </div>
            <div className="link1">
              <img className="icon" loading="lazy" alt="" src="/icon-3.svg" />
              <div className="label7">
                Kişilerin ve kurumların yaptığı işlemleri anlık olarak görebilir
                ve inceleyebilirsiniz.
              </div>
            </div>
          </div>
        </div>
      </section>
      <FrameComponent3 />
      <FooterBlock1 />
    </div>
  );
};

export default MobilSize;

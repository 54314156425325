import Field from "./Field";
import Button from "./Button";
import PropTypes from "prop-types";
import "./FrameComponent3.css";

const FrameComponent3 = ({ className = "" }) => {
  return (
    <section className={`heading-subheading-container ${className}`}>
      <div className="heading-subheading3">
        <div className="heading14">
          <div className="label57">
            <h1 className="destek3">Destek</h1>
          </div>
          <div className="label58">
            <div className="label59">Label</div>
          </div>
          <div className="label58">
            <div className="label59">Label</div>
          </div>
        </div>
        <div className="bizimle-iletiime-geebilir1">
          Bizimle iletişime geçebilir ve şikayet veya taleplerinizi
          bildirebilirsiniz.
        </div>
      </div>
      <div className="contact-form1">
        <div className="block-with-illustration1">
          <div className="radio-buttons">
            <div className="say-hi-button">
              <div className="say-hi">
                <input
                  className="radiobutton3"
                  type="radio"
                  name="radioGroup-1"
                />
                <div className="hi-label">
                  <div className="ikayet1">Şikayet</div>
                </div>
              </div>
              <div className="get-a-quote">
                <input className="radiobutton4" type="radio" />
                <div className="hi-label">
                  <div className="destek4">Destek</div>
                </div>
              </div>
            </div>
            <div className="feature-request-button">
              <div className="get-a-quote">
                <input
                  className="radiobutton4"
                  type="radio"
                  name="radioGroup-1"
                />
                <div className="hi-label">
                  <div className="zellik-talebi1">Özellik Talebi</div>
                </div>
              </div>
            </div>
          </div>
          <div className="fields1">
            <div className="field3">
              <div className="sim2">İsim</div>
              <div className="input2">
                <div className="sim3">İsim</div>
              </div>
            </div>
            <Field
              sim="Email*"
              propMinWidth="49px"
              simPlaceholder="Email"
              propWidth="45px"
            />
            <div className="field3">
              <div className="mesaj1">Mesaj*</div>
              <textarea
                className="input3"
                placeholder="Mesaj"
                rows={10}
                cols={15}
              />
            </div>
          </div>
          <Button
            propBorder="none"
            propBackgroundColor="#191a23"
            propWidth="unset"
            propAlignSelf="stretch"
            label="Gönder"
            propColor="#fff"
            propTextDecoration="unset"
            propDisplay="inline-block"
            propMinWidth="71px"
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;

import PropTypes from "prop-types";
import "./FrameComponent1.css";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <section className={`frame-parent ${className}`}>
      <header className="frame-group">
        <div className="frame-6-1-group">
          <img
            className="frame-6-12"
            loading="lazy"
            alt=""
            src="/frame-6-11@2x.png"
          />
          <div className="finans360-group">
            <a className="finans3603">Finans360</a>
            <div className="ile-yatrm-yapmak2">
              ‘ile Yatırım Yapmak Ne Kolaymış
            </div>
          </div>
        </div>
        <img className="burger-menu-icon" alt="" src="/burger-menu-icon.svg" />
      </header>
      <div className="banner">
        <h2 className="finans360la-her-admda1">
          Finans360’la her adımda bilinçli yatırım !
        </h2>
      </div>
      <img
        className="illustration-1-icon1"
        loading="lazy"
        alt=""
        src="/illustration-12@2x.png"
      />
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;

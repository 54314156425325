import CardsSection from "./CardsSection";
import PropTypes from "prop-types";
import "./FrameComponent.css";

const FrameComponent = ({ className = "" }) => {
  return (
    <section className={`heading-subheading-group ${className}`}>
      <div className="heading-subheading1">
        <div className="heading7">
          <div className="label33">
            <h1 className="zelliklerimiz1">Özelliklerimiz</h1>
          </div>
        </div>
        <div className="sizlere-tamamen-cretsiz">
          Sizlere Tamamen Ücretsiz Olarak Sunulan, Yatırımınızı Güçlendirecek
          Üstün Özelliklerimiz
        </div>
      </div>
      <CardsSection
        label="Yapay Zekalı"
        label1="Kap Analizi"
        illustration="/illustration@2x.png"
        icon="/icon.svg"
        label2="Kap haberleri anlık olarak yapay zeka ile analizlernir ve 1-10 puan arasından finansal olarak puanlanır."
        label3="Finansal Veriler"
        label4="ve Bilançolar"
        illustration1="/illustration-11@2x.png"
        icon1="/icon.svg"
        label5="Şirketlerin finansal verileri en doğru şekilde arşivlenir, analizlenir ve listelenip sizlere sunulur"
      />
      <CardsSection
        propBackgroundColor="#000"
        propPadding="unset"
        propGap="15px"
        propMinWidth="211px"
        propFlex="1"
        propAlignSelf="stretch"
        label="Aracı Kurup Raporları"
        label1="Ve Önerileri"
        illustration="/illustration-2@2x.png"
        propHeight="195.9px"
        icon="/icon-2.svg"
        label2="+15 den Fazla Aracı kurumun günlük ve anlık paylaştığı hisse önerileri ve raporları sizlere sunulur."
        propColor="#fff"
        propBackgroundColor1="#078211"
        propWidth="250px"
        propMinWidth1="250px"
        label3="Aracı Kurumların"
        label4="İşlemleri"
        propDisplay="inline-block"
        propMinWidth2="119px"
        illustration1="/illustration@2x.png"
        icon1="/icon-3.svg"
        label5="Aracı kurumların hangi hissede ne akdar işlem yaptığı veya elinde ne kadar tuttuğunu anlık olarak görün."
      />
      <div className="cards-section3">
        <div className="card6">
          <div className="heading-and-link6">
            <div className="card-header2">
              <div className="heading8">
                <div className="label34">
                  <h3 className="sanal-portfym1">Sanal Portföyüm</h3>
                </div>
              </div>
              <img
                className="illustration-icon6"
                alt=""
                src="/illustration-4@2x.png"
              />
            </div>
            <div className="link6">
              <img className="icon6" alt="" src="/icon.svg" />
              <div className="label35">
                Sizlere sunduğumuz 100 Bin TL lik deneme bakiyesi ile gerçek
                alım-satım yapıyormuş gibi işlemler yapın ve tecrübe edinin.
              </div>
            </div>
          </div>
        </div>
        <div className="card7">
          <div className="heading-and-link7">
            <div className="card-header3">
              <div className="heading9">
                <div className="label36">
                  <div className="likileri-ve1">İş İlişkileri ve</div>
                </div>
                <div className="label37">
                  <div className="temettler1">Temettüler</div>
                </div>
                <div className="label38">
                  <div className="label39">Label</div>
                </div>
              </div>
              <img
                className="illustration-icon7"
                alt=""
                src="/illustration-5@2x.png"
              />
            </div>
            <div className="link7">
              <img className="icon6" alt="" src="/icon.svg" />
              <div className="label35">
                Şirketlerin yaptığı iş anlaşmalarını anlık olarak görün ,
                verdiği temettüleri yıllık olarak analizleyin.
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardsSection
        propBackgroundColor="#000"
        propPadding="unset"
        propGap="15px"
        propMinWidth="211px"
        propFlex="1"
        propAlignSelf="stretch"
        label="Tedbirli Hisseler ve"
        label1="Spk Cezaları"
        illustration="/illustration-6@2x.png"
        propHeight="195.9px"
        icon="/icon-2.svg"
        label2="Hisselerin üzerinde bulunan tedbirleri ve spk nın kişilere ve kurumlara verdiği cezaları görün."
        propColor="#fff"
        propBackgroundColor1="#078211"
        propWidth="246px"
        propMinWidth1="246px"
        label3="Anlık Alım-Satım"
        label4="Analizi"
        propDisplay="inline-block"
        propMinWidth2="94px"
        illustration1="/illustration-7@2x.png"
        icon1="/icon-3.svg"
        label5="Kişilerin ve kurumların yaptığı işlemleri anlık olarak görebilir ve inceleyebilirsiniz."
      />
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;

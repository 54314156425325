import { useMemo } from "react";
import PropTypes from "prop-types";
import "./Field.css";

const Field = ({
  className = "",
  sim,
  propMinWidth,
  simPlaceholder,
  propWidth,
}) => {
  const simStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const sim1Style = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className={`field ${className}`}>
      <div className="sim" style={simStyle}>
        {sim}
      </div>
      <div className="input">
        <input
          className="sim1"
          placeholder={simPlaceholder}
          type="text"
          style={sim1Style}
        />
      </div>
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  sim: PropTypes.string,
  simPlaceholder: PropTypes.string,

  /** Style props */
  propMinWidth: PropTypes.any,
  propWidth: PropTypes.any,
};

export default Field;

import { useMemo } from "react";
import PropTypes from "prop-types";
import "./Button.css";

const Button = ({
  className = "",
  propBorder,
  propBackgroundColor,
  propWidth,
  propAlignSelf,
  label,
  propColor,
  propTextDecoration,
  propDisplay,
  propMinWidth,
}) => {
  const buttonStyle = useMemo(() => {
    return {
      border: propBorder,
      backgroundColor: propBackgroundColor,
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propBorder, propBackgroundColor, propWidth, propAlignSelf]);

  const label3Style = useMemo(() => {
    return {
      color: propColor,
      textDecoration: propTextDecoration,
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propColor, propTextDecoration, propDisplay, propMinWidth]);

  return (
    <button className={`button ${className}`} style={buttonStyle}>
      <div className="label47" style={label3Style}>
        {label}
      </div>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,

  /** Style props */
  propBorder: PropTypes.any,
  propBackgroundColor: PropTypes.any,
  propWidth: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propColor: PropTypes.any,
  propTextDecoration: PropTypes.any,
  propDisplay: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default Button;

import { useMemo } from "react";
import PropTypes from "prop-types";
import "./CardsSection.css";

const CardsSection = ({
  className = "",
  propBackgroundColor,
  propPadding,
  propGap,
  propMinWidth,
  propFlex,
  propAlignSelf,
  label,
  label1,
  illustration,
  propHeight,
  icon,
  label2,
  propColor,
  propBackgroundColor1,
  propWidth,
  propMinWidth1,
  label3,
  label4,
  propDisplay,
  propMinWidth2,
  illustration1,
  icon1,
  label5,
}) => {
  const cardStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const cardHeaderStyle = useMemo(() => {
    return {
      padding: propPadding,
      gap: propGap,
    };
  }, [propPadding, propGap]);

  const headingStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
      flex: propFlex,
    };
  }, [propMinWidth, propFlex]);

  const labelStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const illustrationIconStyle = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  const label1Style = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  const card1Style = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const heading1Style = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth1,
    };
  }, [propWidth, propMinWidth1]);

  const label2Style = useMemo(() => {
    return {
      display: propDisplay,
      minWidth: propMinWidth2,
    };
  }, [propDisplay, propMinWidth2]);

  return (
    <div className={`cards-section2 ${className}`}>
      <div className="card4" style={cardStyle}>
        <div className="heading-and-link4">
          <div className="card-header" style={cardHeaderStyle}>
            <div className="heading5" style={headingStyle}>
              <div className="label21" style={labelStyle}>
                <div className="label22">{label}</div>
              </div>
              <div className="label21">
                <div className="label22">{label1}</div>
              </div>
            </div>
            <img
              className="illustration-icon4"
              loading="lazy"
              alt=""
              src={illustration}
              style={illustrationIconStyle}
            />
          </div>
          <div className="link4">
            <img className="icon4" loading="lazy" alt="" src={icon} />
            <div className="label25" style={label1Style}>
              {label2}
            </div>
          </div>
        </div>
      </div>
      <div className="card5" style={card1Style}>
        <div className="heading-and-link5">
          <div className="card-header1">
            <div className="heading6" style={heading1Style}>
              <div className="label26">
                <div className="label22">{label3}</div>
              </div>
              <div className="label28">
                <div className="label22" style={label2Style}>
                  {label4}
                </div>
              </div>
              <div className="label30">
                <div className="label31">Label</div>
              </div>
            </div>
            <img className="illustration-icon4" alt="" src={illustration1} />
          </div>
          <div className="link5">
            <img className="icon4" alt="" src={icon1} />
            <div className="label25">{label5}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

CardsSection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  label1: PropTypes.string,
  illustration: PropTypes.string,
  icon: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  label4: PropTypes.string,
  illustration1: PropTypes.string,
  icon1: PropTypes.string,
  label5: PropTypes.string,

  /** Style props */
  propBackgroundColor: PropTypes.any,
  propPadding: PropTypes.any,
  propGap: PropTypes.any,
  propMinWidth: PropTypes.any,
  propFlex: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propHeight: PropTypes.any,
  propColor: PropTypes.any,
  propBackgroundColor1: PropTypes.any,
  propWidth: PropTypes.any,
  propMinWidth1: PropTypes.any,
  propDisplay: PropTypes.any,
  propMinWidth2: PropTypes.any,
};

export default CardsSection;

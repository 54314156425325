import Heading from "./Heading";
import PropTypes from "prop-types";
import "./FooterBlock1.css";

const FooterBlock1 = ({ className = "" }) => {
  return (
    <section className={`footer-block1 ${className}`}>
      <div className="footer1">
        <div className="navigation-and-contacts1">
          <div className="elements1">
            <div className="brand-info">
              <img
                className="frame-6-13"
                loading="lazy"
                alt=""
                src="/frame-6-1-11@2x.png"
              />
              <div className="brand-slogan">
                <div className="finans3604">Finans360</div>
                <div className="ile-yatrm-yapmak3">
                  ‘ile Yatırım Yapmak Ne Kolaymış
                </div>
              </div>
            </div>
            <div className="navigation1">
              <div className="zelliklerimiz4">Özelliklerimiz</div>
              <div className="hakkmzda2">Hakkımızda</div>
              <div className="destek5">Destek</div>
            </div>
          </div>
          <div className="contact-info1">
            <div className="contact-us1">
              <Heading />
              <div className="info1">
                <div className="telefon-1-650-457-07311">
                  Telefon : +1-650-457-0731 
                </div>
                <div className="adres-1101-new1">
                  Adres: 1101 New York Ave NW, Washington, DC 20005, Amerika
                  Birleşik Devletleri
                </div>
              </div>
            </div>
            <div className="subscription-block1">
              <div className="field5">
                <div className="email1">Email</div>
              </div>
              <div className="button1">
                <div className="label62">Yeniliklerden Haberdar Olun</div>
              </div>
            </div>
          </div>
        </div>
        <footer className="line-and-text1">
          <div className="footer-divider" />
          <div className="text1">
            <div className="finans3605">© 2024 Finans360.</div>
          </div>
        </footer>
      </div>
    </section>
  );
};

FooterBlock1.propTypes = {
  className: PropTypes.string,
};

export default FooterBlock1;

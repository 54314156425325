import PropTypes from "prop-types";
import "./Heading.css";

const Heading = ({ className = "" }) => {
  return (
    <div className={`heading10 ${className}`}>
      <div className="label41">
        <div className="label42">İletişim:</div>
      </div>
      <div className="label43">
        <div className="label44">Label</div>
      </div>
      <div className="label43">
        <div className="label44">Label</div>
      </div>
    </div>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
};

export default Heading;

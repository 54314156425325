import PropTypes from "prop-types";
import "./FrameComponent2.css";

const FrameComponent2 = ({ className = "" }) => {
  return (
    <section className={`frame-container ${className}`}>
      <div className="finans360-yatrm-dnyasnda-wrapper">
        <div className="finans360-yatrm-dnyasnda1">
          Finans360, yatırım dünyasında güvenle yol almanızı sağlar. Kapsamlı
          analiz araçları ve güncel verilerle, yatırım kararlarınızı bilinçli
          bir şekilde almanıza yardımcı olur. Hangi hisse senedine yatırım
          yapmanız gerektiğini analiz edin, riskleri en aza indirin ve
          kazancınızı maksimize edin. Finans360 ile her yatırım adımınız daha
          emin ve kârlı!
        </div>
      </div>
      <div className="download-component2">
        <img
          className="download-component3"
          loading="lazy"
          alt=""
          src="/download--component1@2x.png"
        />
        <img
          className="download-component3"
          loading="lazy"
          alt=""
          src="/google-play-1@2x.png"
        />
      </div>
      <div className="partnerlerimiz1">Partnerlerimiz</div>
      <div className="logotypes1">
        <img
          className="image-4-icon1"
          loading="lazy"
          alt=""
          src="/image-41@2x.png"
        />
        <img
          className="image-2-icon1"
          loading="lazy"
          alt=""
          src="/image-21@2x.png"
        />
        <img className="image-3-icon1" alt="" src="/image-31@2x.png" />
        <div className="image-5-wrapper">
          <img
            className="image-5-icon1"
            loading="lazy"
            alt=""
            src="/image-51@2x.png"
          />
        </div>
      </div>
      <div className="heading-subheading2">
        <div className="heading11">
          <div className="label48">
            <h1 className="zelliklerimiz3">Özelliklerimiz</h1>
          </div>
        </div>
        <div className="sizlere-tamamen-cretsiz1">
          Sizlere Tamamen Ücretsiz Olarak Sunulan, Yatırımınızı Güçlendirecek
          Üstün Özelliklerimiz
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;

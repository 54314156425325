import Button from "./Button";
import PropTypes from "prop-types";
import "./LandingPage.css";

const LandingPage = ({ className = "" }) => {
  return (
    <section className={`landing-page ${className}`}>
      <header className="navigation-bar">
        <div className="frame-6-1-parent">
          <img
            className="frame-6-1"
            loading="lazy"
            alt=""
            src="/frame-6-1@2x.png"
          />
          <div className="finans360-parent">
            <div className="finans360">Finans360</div>
            <div className="ile-yatrm-yapmak">
              ‘ile Yatırım Yapmak Ne Kolaymış
            </div>
          </div>
        </div>
        <div className="zelliklerimiz-parent">
          <a className="zelliklerimiz">Özelliklerimiz</a>
          <a className="hakkmzda">Hakkımızda</a>
          <a className="destek">Destek</a>
          <Button
            propBorder="1px solid #191a23"
            propBackgroundColor="transparent"
            propWidth="unset"
            propAlignSelf="unset"
            label="Şimdi İndir"
            propColor="#000"
            propTextDecoration="none"
            propDisplay="inline-block"
            propMinWidth="100px"
          />
        </div>
      </header>
      <div className="header">
        <div className="header-content">
          <h1 className="finans360la-her-admda">
            Finans360’la her adımda bilinçli yatırım !
          </h1>
          <div className="finans360-yatrm-dnyasnda">
            Finans360, yatırım dünyasında güvenle yol almanızı sağlar. Kapsamlı
            analiz araçları ve güncel verilerle, yatırım kararlarınızı bilinçli
            bir şekilde almanıza yardımcı olur. Hangi hisse senedine yatırım
            yapmanız gerektiğini analiz edin, riskleri en aza indirin ve
            kazancınızı maksimize edin. Finans360 ile her yatırım adımınız daha
            emin ve kârlı!
          </div>
        </div>
        <img
          className="illustration-1-icon"
          loading="lazy"
          alt=""
          src="/illustration-1@2x.png"
        />
      </div>
      <div className="download-component">
        <img
          className="google-play"
          loading="lazy"
          alt=""
          src="/download--component@2x.png"
        />
        <img
          className="google-play"
          loading="lazy"
          alt=""
          src="/google-play-@2x.png"
        />
      </div>
      <div className="partnerlerimiz-parent">
        <h2 className="partnerlerimiz">Partnerlerimiz</h2>
        <div className="logotypes">
          <img
            className="image-4-icon"
            loading="lazy"
            alt=""
            src="/image-4@2x.png"
          />
          <img
            className="image-2-icon"
            loading="lazy"
            alt=""
            src="/image-2@2x.png"
          />
          <img
            className="image-3-icon"
            loading="lazy"
            alt=""
            src="/image-3@2x.png"
          />
          <img
            className="image-5-icon"
            loading="lazy"
            alt=""
            src="/image-5@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

LandingPage.propTypes = {
  className: PropTypes.string,
};

export default LandingPage;

import LandingPage from "../components/LandingPage";
import FrameComponent from "../components/FrameComponent";
import ContactForm from "../components/ContactForm";
import FooterBlock from "../components/FooterBlock";
import "./AnaSize.css";

const AnaSize = () => {
  return (
    <div className="ana-size">
      <LandingPage />
      <FrameComponent />
      <section className="heading-subheading-parent">
        <div className="heading-subheading">
          <div className="heading">
            <div className="label">
              <h1 className="label1">Destek</h1>
            </div>
            <div className="label2">
              <div className="label3">Label</div>
            </div>
            <div className="label2">
              <div className="label3">Label</div>
            </div>
          </div>
          <div className="bizimle-iletiime-geebilir">
            Bizimle iletişime geçebilir ve şikayet veya taleplerinizi
            bildirebilirsiniz.
          </div>
        </div>
        <ContactForm />
        <FooterBlock />
      </section>
    </div>
  );
};

export default AnaSize;
